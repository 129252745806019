import * as React from "react";
import "./get-in-touch.scss";
import "../../styles/shared.scss";
import { PageTitle } from "../../components/title/title";
import classNames from "classnames";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from "../../components/layout";
// import {pixelDrop} from '../../utils/pixel-drop';
import HubspotForm from "../../components/hubspot-form";

type GetInTouchPageState = {
  firstName: string;
  firstNameError: boolean;
  lastName: string;
  lastNameError: boolean;
  email: string;
  emailError: boolean;
  bizEmailError: boolean;
  company: string;
  companyError: boolean;
  position: string;
  positionError: boolean;
  helpWith: string;
  helpWithError: boolean;
  textMessage: string;
  textMessageError: boolean;
  subscribeToNewsletter: boolean;

  foundError: boolean;
  pressedSubmit: boolean;
  submitted: boolean;
};

class GetInTouchPage extends React.Component<
  {
    location: Location;
  },
  GetInTouchPageState
> {
  constructor(props: {}) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      position: "",
      helpWith: "0",
      textMessage: "",
      subscribeToNewsletter: false,

      firstNameError: false,
      lastNameError: false,
      emailError: false,
      companyError: false,
      positionError: false,
      helpWithError: false,
      bizEmailError: false,
      textMessageError: false,

      pressedSubmit: false,
      foundError: false,
      submitted: false,
    };

    this.updateInput = this.updateInput.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.getData = this.getData.bind(this);
    this.submit = this.submit.bind(this);
    this.getInputClasses = this.getInputClasses.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  resetForm() {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      position: "",
      helpWith: "",
      textMessage: "",

      firstNameError: false,
      lastNameError: false,
      emailError: false,
      bizEmailError: false,
      companyError: false,
      positionError: false,
      helpWithError: false,
      textMessageError: false,
    });
  }

  updateInput(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) {
    const target = event.target;
    if (!target) {
      return;
    }
    const value = target.value as string;
    const name = target.name as keyof GetInTouchPageState;

    this.setState({
      [name]: value,
    });
  }

  getData() {
    return {
      page: this.props.location.href,
      ...this.state,
    };
  }

  getInputClasses(name: string): string {
    const errorKey = `${name}Error`;
    return classNames("form-control", {
      invalid: this.state[errorKey],
      grey:
        name === "helpWith" &&
        (this.state.helpWith === "0" || !this.state.helpWith),
    });
  }

  isValid(
    event?:
      | null
      | React.FormEvent<any>
      | React.KeyboardEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
  ): boolean {
    // If event exists, only test the correct field
    let limitTestTo = null;
    let foundError = false;
    if (event && event.target && event.target.name) {
      limitTestTo = event.target.name;
    }

    if (!limitTestTo || limitTestTo === "email") {
      if (!this.state.email || this.state.email.indexOf("@") === -1) {
        this.setState({ emailError: true });
        foundError = true;
      } else {
        this.setState({ emailError: false, bizEmailError: false });
      }
    }

    if (!limitTestTo || limitTestTo === "firstName") {
      if (!this.state.firstName) {
        this.setState({ firstNameError: true });
        foundError = true;
      } else {
        this.setState({ firstNameError: false });
      }
    }

    if (!limitTestTo || limitTestTo === "position") {
      if (!this.state.position) {
        this.setState({ positionError: true });
        foundError = true;
      } else {
        this.setState({ positionError: false });
      }
    }

    if (!limitTestTo || limitTestTo === "helpWith") {
      if (!this.state.helpWith) {
        this.setState({ helpWithError: true });
        foundError = true;
      } else {
        this.setState({ helpWithError: false });
      }
    }

    this.setState({ foundError });
    return !foundError;
  }

  submit(e: React.FormEvent) {
    this.setState({ foundError: false, pressedSubmit: true });

    if (!this.isValid()) {
      e.preventDefault();
      return false;
    }

    if (this.state.subscribeToNewsletter) {
      fetch("/api/subscribe/", {
        method: "POST",
        body: JSON.stringify(this.getData()),
        headers: {
          "Content-Type": "application/json",
        },
      }).catch((err) => {
        console.error("Error subscribing", err);
      });
    }

    fetch("/api/contact/", {
      method: "POST",
      body: JSON.stringify(this.getData()),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((val) => val.json())
      .then((val) => {
        if (val.error) {
          if (
            val.details &&
            val.details.errors &&
            val.details.errors.length &&
            val.details.errors[0].errorType
          ) {
            const hsErrorType = val.details.errors[0].errorType;
            if (hsErrorType === "BLOCKED_EMAIL") {
              this.setState({ bizEmailError: true, foundError: true });
            }
          }
        } else {
          this.resetForm();
          this.setState({ submitted: true });
          // pixelDrop('get-in-touch');
          setTimeout(() => this.setState({ submitted: false }), 10 * 1000);
          window.location.href = "/get-in-touch/thank-you";
        }
      })
      .catch((err) => {
        console.error("Error!", err);
      });
  }

  render() {
    return (
      <AmazeePage location={this.props.location}>
        <div id="get-in-touch">
          <PageTitle title="Contact Us 24/7/365 | amazee.io" />
          <AmazeeIoMetadata title="Contact Us" location={this.props.location} />
          <header className="header__wrapper">
            <div className="container">
              <div className="row">
                <div className="header col-12 d-flex flex-col items-center justify-center">
                  <div className="header__title">Get in touch</div>
                  <div className="header__subtitle">
                    We’re ready to help! Fill out our form below for help on
                    your next open source project, assistance with preparing for
                    high-traffic events, migration planning or any other
                    questions you may have.
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section id="form_section">
            <div className="container">
              <div className="row flex justify-center mt-16 mb-16">
                <div className="col-12 col-md-10 col-lg-8">
                  <HubspotForm
                    formId={"674cc84b-dcd7-4db2-af4c-b7ab4f7fd165"}
                    portalId={"5096486"}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </AmazeePage>
    );
  }
}

export default GetInTouchPage;
